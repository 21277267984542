<template>
  <div>
    <q-banner v-if="new Date() <= new Date('2024-01-01')" class="bg-cyan-2 text-info q-my-md" rounded>
      <q-item>
        <q-item-section top avatar>
          <q-icon name="mdi-information-outline" size="lg" />
        </q-item-section>
        <q-item-section>
          <q-item-label caption class="text-black">
            <strong>Prazos de pagamento para dezembro</strong><br />
          </q-item-label>
          <q-item-label caption class="text-black">
            Pagamentos solicitados até o dia <b>dez (10)</b>, serão pagos até o dia <b>quinze (15)</b> e pagamentos
            solicitados no dia <b>quinze (15)</b>, serão pagos até o dia <b>vinte (20)</b>.
          </q-item-label>
        </q-item-section>
      </q-item>
    </q-banner>

    <div class="row q-col-gutter-md">
      <div class="col-8">
        <q-card flat bordered class="q-mb-md">
          <q-toolbar class="bg-primary text-white">
            <q-toolbar-title class="text-body1"> Comissões disponíveis para pagamento </q-toolbar-title>
          </q-toolbar>
          <q-card-section>
            <div class="row">
              <div class="col-8">Descrição</div>
              <div class="col-2">Valor</div>
              <div class="col-2 text-center">Detalhes</div>
            </div>
            <q-separator spaced />
            <div class="row">
              <div class="col-8">Comissões</div>
              <div class="col-2">{{ parseMoney(commissionAvailable) }}</div>
              <div class="col-2 text-center">
                <q-btn
                  color="primary"
                  icon="mdi-plus"
                  unelevated
                  flat
                  dense
                  rounded
                  :disable="commissionAvailable === 0"
                  @click="onShowDetails('2')"
                />
              </div>
            </div>
            <q-separator spaced />
            <div class="row">
              <div class="col-8">Total final disponível para pagamento</div>
              <div class="col-2">{{ parseMoney(commissionAvailable) }}</div>
              <div class="col-2 text-center"></div>
            </div>
          </q-card-section>
          <q-card-section class="text-center">
            <q-btn
              color="positive"
              class="q-px-md"
              no-caps
              label="Solicitar pagamento"
              unelevated
              @click="onRequestPayment"
              :disable="canNotAskForPayment"
            />
          </q-card-section>
        </q-card>
        <q-card flat bordered class="q-mb-md">
          <q-toolbar class="bg-positive text-white">
            <q-toolbar-title class="text-body1"> Pagamentos em andamento </q-toolbar-title>
          </q-toolbar>
          <q-card-section>
            <div class="row">
              <div class="col-8">Descrição</div>
              <div class="col-2">Valor</div>
              <div class="col-2 text-center">Detalhes</div>
            </div>
            <q-separator spaced />
            <div class="row">
              <div class="col-8">Comissões</div>
              <div class="col-2">{{ parseMoney(commissionProgress) }}</div>
              <div class="col-2 text-center">
                <q-btn
                  color="primary"
                  icon="mdi-plus"
                  flat
                  unelevated
                  :disable="commissionProgress === 0"
                  @click="onShowDetails('3,12,4')"
                  dense
                  rounded
                />
              </div>
            </div>
            <q-separator spaced />
            <div class="row">
              <div class="col-8">Total final disponível para pagamento</div>
              <div class="col-2">{{ parseMoney(commissionProgress) }}</div>
              <div class="col-2 text-center"></div>
            </div>
          </q-card-section>
        </q-card>
        <q-card flat bordered class="q-mb-md">
          <q-toolbar class="bg-warning text-white">
            <q-toolbar-title class="text-body1"> Comissões futuras </q-toolbar-title>
          </q-toolbar>
          <q-card-section>
            <div class="row">
              <div class="col-8">Descrição</div>
              <div class="col-2">Valor</div>
              <div class="col-2 text-center">Detalhes</div>
            </div>
            <q-separator spaced />
            <div class="row">
              <div class="col-8">Comissões</div>
              <div class="col-2">{{ parseMoney(commissionFutures) }}</div>
              <div class="col-2 text-center">
                <q-btn
                  color="primary"
                  flat
                  icon="mdi-plus"
                  unelevated
                  dense
                  :disable="commissionFutures === 0"
                  rounded
                  @click="onShowDetails('1')"
                />
              </div>
            </div>
            <q-separator spaced />
            <div class="row">
              <div class="col-8">Total final de comissões futuras</div>
              <div class="col-2">{{ parseMoney(commissionFutures) }}</div>
              <div class="col-2 text-center"></div>
            </div>
          </q-card-section>
        </q-card>
      </div>
      <div class="col-4">
        <q-card flat bordered v-if="!idAffiliateFromAdmin">
          <q-toolbar class="bg-grey-3 text-dark">
            <q-toolbar-title class="text-body1"> Dúvidas Frequentes </q-toolbar-title>
          </q-toolbar>
          <q-card-section>
            <q-list>
              <q-expansion-item expand-separator label="Como solicito meu pagamento?">
                <q-card>
                  <q-card-section>
                    É necessário ter no mínimo R$ 500,00 de lucros acumulados. Quando atingir esse valor, o botão
                    'Solicitar pagamento' ficará habilitado para que você possa clicar e solicitar seu pagamento.
                  </q-card-section>
                </q-card>
              </q-expansion-item>

              <q-expansion-item expand-separator label="Como funcionam os prazos?">
                <q-card>
                  <q-card-section>
                    Os pagamentos são realizados todo dia quinze (15) e vinte e cinco (25) de cada mês. Pagamentos
                    solicitados até o dia dez (10), serão pagos no dia quinze (15) e pagamentos solicitados até o dia
                    vinte (20), serão pagos no dia vinte e cinco (25).
                  </q-card-section>
                </q-card>
              </q-expansion-item>

              <q-expansion-item expand-separator label="Onde insiro minhas informações para pagamento?">
                <q-card>
                  <q-card-section>
                    Em 'Minha conta' você deverá inserir as informações na aba 'Informações de pagamento'. Os pagamentos
                    serão realizados somente se seus dados estiverem atualizados na plataforma. Se preferir emitir Nota
                    Fiscal, por favor envie para pagamento@afil.io após receber sua fatura automática, confirmando o
                    valor a ser pago. A Nota fiscal deverá ter o mesmo valor da fatura recebida, e enviada em até 2 dias
                    da data de pagamento.
                  </q-card-section>
                </q-card>
              </q-expansion-item>
            </q-list>
          </q-card-section>
        </q-card>
        <q-card flat bordered :class="!idAffiliateFromAdmin ? 'q-my-md' : ''">
          <q-toolbar class="bg-grey-3 text-dark">
            <q-toolbar-title class="text-body1"> Extrato</q-toolbar-title>
          </q-toolbar>
          <q-card-section>
            <date-input
              outlined
              label="Intervalo de data da transação"
              v-model="date"
              @update="(value) => (date = value)"
              clearable
              range
            />
            <center>
              <statements-invoice
                :id="idAffiliateFromAdmin ? idAffiliateFromAdmin : user.affiliate_id"
                :date-select="date"
              />
            </center>
          </q-card-section>
        </q-card>
      </div>
      <commissionsDetails
        :id="idAffiliateFromAdmin ? idAffiliateFromAdmin : user.affiliate_id"
        :show="showDetail"
        @close="showDetail = false"
        :status="selectStatus"
      />
    </div>
  </div>
</template>

<script>
import FinanceService from '../../services/FinanceService';
import AffiliateService from '../../services/AffiliateService';
import StatementsInvoice from './financial/StatementsInvoice';
import DateInput from '../../widgets/inputsGlobal/DateInput.vue';
import commissionsDetails from './financial/CommissionsDetails';
export default {
  name: 'ListFinancialNew',
  mixins: [FinanceService, AffiliateService],
  components: {
    StatementsInvoice,
    commissionsDetails,
    DateInput
  },
  props: {
    idAffiliateFromAdmin: {
      type: [String, Number]
    }
  },
  data() {
    return {
      date: null,
      commissionAvailable: 0,
      commissionProgress: 0,
      commissionFutures: 0,
      minimumPayment: 0,
      affiliatePaymentInfos: {},
      selectStatus: null,
      showDetail: false
    };
  },
  methods: {
    async setupCommissionAvailable() {
      let affiliateId;
      if (this.idAffiliateFromAdmin) affiliateId = this.idAffiliateFromAdmin;
      else affiliateId = this.user.affiliate_id;

      try {
        const { data, status } = await this.getCommissionSumByState(affiliateId, '2');
        if (status === 200 || status === 201) this.commissionAvailable = parseFloat(data.data);
      } catch (error) {
        console.log(error);
      }
    },
    async setupCommissionProgress() {
      let affiliateId;
      if (this.idAffiliateFromAdmin) affiliateId = this.idAffiliateFromAdmin;
      else affiliateId = this.user.affiliate_id;

      try {
        const { data, status } = await this.getCommissionSumByState(affiliateId, '3,12,4');
        if (status === 200 || status === 201) this.commissionProgress = parseFloat(data.data);
      } catch (error) {
        console.log(error);
      }
    },
    async setupCommissionFutures() {
      let affiliateId;
      if (this.idAffiliateFromAdmin) affiliateId = this.idAffiliateFromAdmin;
      else affiliateId = this.user.affiliate_id;

      try {
        const { data, status } = await this.getCommissionSumByState(affiliateId, '1');
        if (status === 200 || status === 201) {
          this.commissionFutures = parseFloat(data.data);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async setupAffiliateSettings() {
      this.onLoading(true);

      let affiliateId;
      if (this.idAffiliateFromAdmin) affiliateId = this.idAffiliateFromAdmin;
      else affiliateId = this.user.affiliate_id;

      try {
        const { data, status } = await this.getAffiliateSettings(affiliateId);
        if (status === 200 || status === 201) this.minimumPayment = parseFloat(data.minimum_payment_value);
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },
    async setupAffiliatePaymentInfos() {
      let affiliateId;
      if (this.idAffiliateFromAdmin) affiliateId = this.idAffiliateFromAdmin;
      else affiliateId = this.user.affiliate_id;

      try {
        const { data, status } = await this.getAffiliatePaymentInfos(affiliateId);
        if (status === 200 || status === 201) this.affiliatePaymentInfos = data[0];
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },
    async paymentRequest() {
      this.onLoading(true);

      let affiliateId;
      if (this.idAffiliateFromAdmin) affiliateId = this.idAffiliateFromAdmin;
      else affiliateId = this.user.affiliate_id;

      try {
        let paymentRequest = {
          affiliate_id: affiliateId,
          document_type: this.affiliatePaymentInfos.document_type,
          document_number: this.affiliatePaymentInfos.document_number,
          business_name: this.affiliatePaymentInfos.business_name,
          trademark: this.affiliatePaymentInfos.trademark,
          email: this.affiliatePaymentInfos.email,
          phone_number: this.affiliatePaymentInfos.phone_number
        };

        const { data, status } = await this.requestAffiliatePayment(paymentRequest);
        if (status === 200 || status === 201) {
          this.successNotify(data.data);
          await this.setupPage();
        }
      } catch (error) {
        let msgError = error.response.data?.data?.msg;
        if (msgError) {
          this.errorNotify(msgError[Object.keys(msgError)[0]]);
        } else {
          this.errorNotify(error);
        }
      } finally {
        this.onLoading(false);
      }
    },
    validatorPaymentInfo() {
      if (
        this.affiliatePaymentInfos.document_type === null ||
        this.affiliatePaymentInfos.document_number === null ||
        // this.affiliatePaymentInfos.phone_number === null ||
        this.affiliatePaymentInfos.email === null
      )
        return false;

      if (this.affiliatePaymentInfos.bank_country === 'Brasil') {
        if (
          this.affiliatePaymentInfos.bank_account_number === null ||
          this.affiliatePaymentInfos.bank_account_number_digit === null ||
          this.affiliatePaymentInfos.bank_account_type === null ||
          this.affiliatePaymentInfos.bank_agency === null ||
          this.affiliatePaymentInfos.bank_name === null
        )
          return false;
      }

      if (this.affiliatePaymentInfos.bank_country !== 'Brasil') {
        if (
          this.affiliatePaymentInfos.aba === null &&
          this.affiliatePaymentInfos.bic === null &&
          this.affiliatePaymentInfos.iban === null &&
          this.affiliatePaymentInfos.other_type === null &&
          this.affiliatePaymentInfos.other_value === null &&
          this.affiliatePaymentInfos.routing_number === null &&
          this.affiliatePaymentInfos.swift === null &&
          this.affiliatePaymentInfos.trademark === null
        )
          return false;
      }
      return true;
    },
    async onRequestPayment() {
      if (this.commissionAvailable < this.minimumPayment) {
        this.showInfoDialog(
          'Não é possível solicitar o pagamento',
          `O valor mínimo necessário em comissões, para solicitar pagamento é de R$ ${this.parseMoney(
            this.minimumPayment
          )}`
        );
        return false;
      }
      if (!this.validatorPaymentInfo()) {
        this.showInfoDialog(
          'Informações para pagamento incompletas',
          'Por favor, complete suas informações bancárias e dados de contato, para solicitar o pagamento.',
          () => {
            this.$router.push('/account/myaccount');
          }
        );
        return false;
      }
      await this.paymentRequest();
    },
    async setupPage() {
      await this.setupCommissionAvailable();
      await this.setupCommissionProgress();
      await this.setupCommissionFutures();
      await this.setupAffiliateSettings();
      await this.setupAffiliatePaymentInfos();
    },
    onShowDetails(status) {
      this.selectStatus = status;
      this.showDetail = true;
    }
  },

  computed: {
    canNotAskForPayment: function () {
      return this.minimumPayment == 0 || this.commissionAvailable < this.minimumPayment;
    }
  },
  created() {
    this.setupPage();
  }
};
</script>
